import * as MapUtils from '@/utils/map'
import * as enums from '@/utils/enum'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      infoTypes: [
        enums.datapoints.SITE_AGGREGATE_MATERIAL,
        enums.datapoints.SITE_ASPHALT_MATERIAL,
      ],
      sectionTypes: [
        enums.datapoints.SITE_AGGREGATE_SECTION,
        enums.datapoints.SITE_ASPHALT_SECTION,
      ],
    }
  },

  watch: {
    async idproject() {
      await this.getDatapoints()
    },
  },

  computed: {
    ...mapState(['datapoints', 'selectedDate']),
    ...mapGetters(['userHasRole']),

    mixin_center() {
      return MapUtils.datapointsCenter(this?.mixin_combinedDatapoints || [])
    },
    mixin_datapointsForDate() {
      if (this.datapoints && this.selectedDate) {
        return this.datapoints.filter(
          (d) => d.date === this.selectedDate && d.location,
        )
      }
      return []
    },
    mixin_nonSectionDatapoints() {
      return this.mixin_datapointsForDate?.filter(
        ({ type }) =>
          !this.sectionTypes.includes(type) && !this.infoTypes.includes(type),
      )
    },
    mixin_sectionDatapoints() {
      const filtered = this.mixin_datapointsForDate?.filter(
        (point) =>
          this.sectionTypes.includes(point.type) &&
          point.sectionStartLocation &&
          point.sectionStartStation &&
          point.sectionEndLocation &&
          point.sectionEndStation,
      )

      const result = []
      for (const point of filtered) {
        result.push({
          ...point,
          location: point.sectionStartLocation,
          station: point.sectionStartStation,
          isStart: true,
          _id: `${point._id}-start`,
        })
        result.push({
          ...point,
          location: point.sectionEndLocation,
          station: point.sectionEndStation,
          isStart: false,
          _id: `${point._id}-end`,
        })
      }

      return result
    },
    mixin_combinedDatapoints() {
      return this.mixin_sectionDatapoints.concat(
        this.mixin_nonSectionDatapoints,
      )
    },
    isProjectManager() {
      return this.userHasRole('projectOwner')
    },
  },

  methods: {
    ...mapActions(['updateDashboard']),

    async getDatapoints() {
      await this.updateDashboard({
        dashboard: 'site-control-technician',
        idproject: this.idproject,
        user: this.user || this.userId,
      })
    },
  },
}
